@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
/* Landing  */



.btn {
  padding: 3em 0;
  text-align: center;
}

.btn a {
  border-radius: 6px;
  background: #6CC9D1;
  width: 170px;
  height: 41px;
  padding: 10px 30px;
  color: #FFF;
  Font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.956px;
  text-decoration: none
}

.basic .entry-title {
  background: #F62B8D;
}

.standard .entry-title {
  background: #5C15C2;
}


.basic .entry-title .price {
  color: #F62B8D;
}

.standard .entry-title .price {
  color: #5C15C2;
}

.price {
  text-align: center;
  margin-top: 52px;
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  Font-family: 'Poppins', sans-serif;
  padding: 30px 15px;
}

.plan-inner {
  border-radius: 0px 0px 12px 12px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
}

.entry-title {
  border-radius: 12px 12px 0px 0px;
  background: #6CC9D1;
  height: 260px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 80px;
  padding: 0 20px;
  border-bottom: 15px solid #F4F2F2;
}

.entry-title>h3 {
  color: #FFF;
  Font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 80.956px;
  margin: 0;
}

.entry-title p {
  color: #FFF;
  Font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.956px;
  margin: 0;
}

.entry-title .price {
  position: absolute;
  bottom: -80px;
  background: #FFF;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 100px;
  color: #2B7A81;
  width: 158px;
  height: 158px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.price span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.entry-content {
  color: #323232;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  padding: 5px 0;
  color: #3A3A3A;
  Font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.entry-content li img {
  margin-right: 20px;
}


.grid-gap {
  padding-left: 0px;
  padding-right: 0px;
}


/* Media Query */
@media only screen and (min-width: 1024px) {
  .celebrating_main_section {
    margin: 0;
  }

}


@media only screen and (max-width: 767px) {
  .topheader .row {
    justify-content: space-between;
  }

  .topheader li:nth-child(2) {
    margin: 0;
  }

  .topheader ul {
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .topheader li a {
    font-size: 14px;
  }

  .vendor-landing .landingbanner .landingbanner-content .landingheader-title,
  .landingbanner .landing-about-title,
  .vendor-landing .marketing-option .row .marketing-title,
  .vendor-landing .marketing-solution .row .marketing-title,
  .vendor-landing .celebrating-diffrence-maker .celebrating-diffrence-maker-title,
  .vendor-landing .marketing_journey .marketing_journey-title {
    font-size: 30px;
    line-height: 1.5;
  }

  .vendor-landing .landingbanner .landingbanner-content .vendor-landing-subtitle,
  .Restaurant-Suppliers .Restaurant-Suppliers-subtitle,
  .vendor-landing .celebrating-diffrence-maker .celebrating-diffrence-maker-subtitle,
  .vendor-landing .marketing_journey .marketing_journey-subtitle,
  .vendor-landing .celebrating-diffrence-maker .celebrating-inner-title,
  .vendor-landing .marketing_journey .marketing_left_side_content h4 {
    font-size: 20px;
    line-height: 1.5;
  }

  .landingbanner .landing-about-detail,
  .Restaurant-Suppliers .Restaurant-Suppliers-detail,
  .vendor-landing .celebrating-diffrence-maker .celebrating-diffrence-maker-detail,
  .vendor-landing .celebrating-diffrence-maker .celebrating-inner-detail,
  .vendor-landing .celebrating-diffrence-maker .entry-title p,
  .vendor-landing .marketing_journey .marketing_left_side_content p {
    font-size: 16px;
    line-height: 1.5;
  }

  .landingbanner .landing-about {
    padding-top: 0;
  }

  .Restaurant-Suppliers,
  .marketing_journey,
  .celebrating-diffrence-maker {
    width: 90%;
    margin: 0 auto;
  }

  .landing-header .container,
  .container {
    max-width: 100% !important;
  }

  .Restaurant-Suppliers .row {
    padding: 40px;
    width: 100%;
    margin: 0;
  }

  .Restaurant-Suppliers .row .MuiGrid-spacing-xs-8 {
    width: 100%;
    margin: 0;
  }

  .Restaurant-Suppliers .illustration1 {
    padding: 0 10px !important;
  }

  .vendor-landing .marketing_journey .marketing_border_box .circle_box {
    width: 170px;
    height: 170px;
    border: 15px solid #E3308D;
  }

  .vendor-landing .marketing_journey .markiting-main {
    flex-wrap: wrap;
  }

  .vendor-landing .marketing_journey .marketing_border_box .circle_box h5 {
    font-size: 16px;
  }

  .vendor-landing .marketing_journey .marketing_left_side {
    width: 100%;
    padding-left: 0;
  }

  .vendor-landing .celebrating-diffrence-maker .celebrating_inner {
    max-width: 100%;
    float: none;
    padding: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .vendor-landing .celebrating-diffrence-maker .img_bx_celebrating_inner {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    width: 80px;
    height: 80px;
    min-height: auto;
  }

  .vendor-landing .celebrating-diffrence-maker .img_bx_celebrating_inner img {
    width: 50px;
  }
}
@media(max-width: 1240px){
.plan {
margin: 0 15px;
}
}
